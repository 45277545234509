<template>
  <div>
    <a-drawer
      :title="title"
      :width="720"
      :visible="show"
      :body-style="{ paddingBottom: '80px' }"
      @close="addClose"
    >
      <div class="drawer-content">
        基本信息
        <a-divider></a-divider>
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          layout="vertical"
        >
          <a-form-model-item prop="inspectionRouteId" label="巡检路线">
            <a-select
              v-model="form.inspectionRouteId"
              placeholder="请选择"
              style="width: 60%"
            >
              <a-select-option
                v-for="item in options.routeList"
                :key="item.id"
                :value="item.id"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="name" label="计划名称">
            <a-input
              v-model="form.name"
              placeholder="请输入"
              style="width: 60%"
            ></a-input>
          </a-form-model-item>
          <a-form-model-item prop="positionId" label="部门">
            <a-cascader
			  v-model="positionValue"
              :options="options.positionId"
              :field-names="{
                label: 'name',
                value: 'id',
                children: 'childList',
              }"
              placeholder="请选择"
              style="width: 60%"
              @change="changePosition"
            ></a-cascader>
          </a-form-model-item>
          <a-form-model-item prop="inspector" label="巡检人">
            <a-select
              v-model="form.inspector"
              placeholder="请选择"
              style="width: 60%"
            >
			<a-select-option
                v-for="item in options.inspector"
                :key="item.id"
                :value="item.id"
                >{{ item.actualName }}</a-select-option
              >
			</a-select>
          </a-form-model-item>
          <a-form-model-item prop="planBeginDate" label="计划开始时间">
            <a-date-picker
              placeholder="请选择开始时间"
              v-model="form.planBeginDate"
              value-format="YYYY-MM-DD HH:mm:ss"
			  style="width: 30%"
			  showTime
            />
          </a-form-model-item>
          <a-form-model-item prop="isSort" label="是否按照顺序巡检">
            <a-select
              v-model="form.isSort"
              placeholder="请选择"
              style="width: 60%"
            >
              <a-select-option
                v-for="item in options.isSort"
                :key="item.id"
                :value="item.id"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="checkRateType" label="检查频率">
            <a-select
              v-model="form.checkRateType"
              placeholder="请选择"
              style="width: 60%"
            >
              <a-select-option
                v-for="item in options.checkRateType"
                :key="item.id"
                :value="item.id"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div class="drawer-footer">
        <a-button :style="{ marginRight: '8px' }" @click="addClose">
          关闭
        </a-button>
        <a-button type="primary" @click="submit"> 提交 </a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { form, rules, options } from "./form.js";
import {
	planInsert,
	planUpdate,
	planInfo,
	routeSelect,
} from "@/api/operation/inspection/plan.js";
import { departmentList, findToUser } from "@/api/basic/department/index.js";
export default {
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		editId: Number,
	},
	data() {
		return {
			title: "新增巡检计划",
			form,
			rules,
			options,
			positionValue:[]
		};
	},
	mounted() {
		this.getRoute();
		this.getDepartment();
	},
	methods: {
		async getRoute() {
			let res = await routeSelect();
			this.options.routeList = res.data;
		},
		async getDepartment() {
			let res = await departmentList();
			this.options.positionId = res.data;
			this.positionDisabled(this.options.positionId)
		},
		// 岗位和部门id会一样，通过不同type==='部门'修改为disable
		positionDisabled(arr){
			arr.forEach((item,index)=>{
				if(item.type=== 1 && (item.childList.length===0 || item.childList===null)){
					arr[index].disabled=true
				}else if(item.type === 2){
					arr[index].disabled=false
				}else{
					this.positionDisabled(item.childList)
				}
			})
		},
		async getUser(id){
			let res = await findToUser({
				// 接口有分页，传10000，后续等通知
				pageNum:1,size:10000,positionId:id
			})
			this.options.inspector = res.data.rows;
		},
		getPositionValue(id){
			console.log(this.options.positionId);
		},
		async changePosition(data) {
			this.options.inspector = []
			this.form.inspector = undefined
			let id = data[data.length - 1]
			this.form.positionId = id
			this.getUser(id)
		},
		addClose() {
			this.$refs.ruleForm.resetFields();
			this.positionValue = []
			this.form.positionId = undefined;
			this.form.inspector = undefined
			this.$emit("addClose");
		},
		success() {
			this.$emit("success");
			this.addClose();
		},
		submit() {
			this.$refs.ruleForm.validate(async (valid) => {
				if (valid) {
					if (this.editId === null) {
						let res = await planInsert(this.form);
						if (res.code === 200) {
							this.$message.success(res.msg);
							this.success();
						} else {
							this.$message.error(res.msg);
						}
					} else {
						let res = await planUpdate(this.form);
						if (res.code === 200) {
							this.$message.success(res.msg);
							this.success();
						} else {
							this.$message.error(res.msg);
						}
					}
				}
			});
		},
	},
	watch: {
		editId: {
			handler(val) {
				if (val !== null) {
					this.title = "修改巡检计划";
					this.form.id = val;
					planInfo({ planId: val }).then((res) => {
						this.form = res.data;
						// 人员反显
						this.getUser(this.form.positionId)
						// 部门反显
						// this.form.positionId
					});
				} else {
					this.title = "新增巡检计划";
				}
			},
			immediate: true,
		},
	},
};
</script>

<style></style>

export const formItem = [
	{
		type: 'input',
		label:'编号',
		prop:'code',
		placeholder:'请输入'
	},
	{
		type: 'input',
		label:'名称',
		prop:'name',
		placeholder:'请输入'
	},
	{
		type: 'select',
		label:'状态',
		prop:'status',
		placeholder: '请选择',
		option:[{ id:1,name:'启用'},{ id:2,name:'停用'}]
	},
	{
		type: 'select',
		label:'巡检路线',
		prop:'inspectionRouteId',
		placeholder: '请选择',
		option:[]
	},
	{
		type: 'time',
		label: '计划时间',
		start: 'planBeginDateStart',
		end:'planBeginDateEnd'
	},
	{
		type: 'time',
		label: '实际时间',
		prop:'actualBeginDate',
		start: 'actualBeginDateStart',
		end:'actualBeginDateEnd'
	},
]
export const columns = [
	
	{
		title: "巡检计划编号",
		dataIndex: "code",
	},
	{
		title: "名称",
		dataIndex: "name",
	},
	{
		title: "巡检路线编号",
		dataIndex: "routeCode",
	},
	{
		title: "计划开始时间",
		dataIndex: "planBeginDate",
	},
	{
		title: "实际开始时间",
		dataIndex: "actualBeginDate",
	},
	{
		title: "部门名称",
		dataIndex: "positionName",
	},
	{
		title: "巡检人名称",
		dataIndex: "inspectorName",
	},
	{
		title: "状态",
		dataIndex: "status",
		customRender: function (status) {
			switch (status) {
			case 1:
				return '启用'
			case 2:
				return '停用'
			}
		}
	},
	{
		title: "操作",
		dataIndex: "action",
		key: "action",
		width: "280",
		fixed: "right",
		scopedSlots: { customRender: "action" },
	},
]
export const pagination = {
	current: 1,
	total: 0,
	pageSize: 10,
	showTotal: (total) => `共 ${total} 条`,
	showSizeChanger: true,
	showQuickJumper: true,
}
export const form = {
	id: undefined,
	inspectionRouteId: undefined,
	name:undefined,
	positionId:undefined,
	inspector:undefined,
	planBeginDate:undefined,
	isSort:undefined,
	checkRateType:undefined,
}
export const rules = {
	inspectionRouteId:[{required:true,message:'请选择路线',trigger:'change'}],
	name:[{required:true,message:'请输入名称',trigger:'blur'}],
	inspector:[{required:true,message:'请选择',trigger:'change'}],
	positionId: [{required:true,message:'请选择部门',trigger:'change'}],
	planBeginDate:[{required:true,message:'请选择日期',trigger:'change'}],
	isSort:[{required:true,message:'请选择',trigger:'change'}],
	checkRateType:[{required:true,message:'请选择',trigger:'change'}],
}
export const options = {
	isSort:[
		{ id:1, name:'顺序' },
		{ id:2, name:'不顺序' },
	],
	routeList: [],
	checkRateType: [
		{ id:1, name:'每天' },
		{ id:2, name:'每周' },
		{ id:3, name:'每月' },
	],
	// 岗位选择
	positionId: [],
	// 人员选择
	inspector:[]
}